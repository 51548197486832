import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Button, Form, FormGroup, Label, Input} from 'reactstrap';



const ImportSagexPanel = () => {
    return (
        <div>
            <p>
                <strong>Disclaimer:</strong> Cet outil est fourni "tel quel", sans garantie d'aucune sorte. Il est impératif de vérifier systématiquement les données avant leur importation dans SageX ainsi que de respecter les directives SageX en vigueur.
            </p>
            <ol>
                <li><a href="https://agp.hes-so.ch/" target="_blank">Se rendre dans SageX > Heures > Transfert des
                    heures</a></li>
                <li>Effectuer l'import en chargeant le fichier précédemment exporté</li>
            </ol>
        </div>
    );
};

export default ImportSagexPanel;
