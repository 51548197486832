import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Button, Form, FormGroup, Label, Input} from 'reactstrap';



const ImportOutlookPanel = () => {
    return (
        <div>
            <p className="panel-instruction">
                Il est nécessaire de créer des catégories pour chaque projet dans Outlook. Ensuite, il faudra les attribuer aux événements (temps passé sur le projet) dans l'agenda.
            </p>

            <ol>
                <li>
                    Dans la création d'un nouveau rdv, rajouter toutes les catégories liées à vos projets <br />
                    Les catégories doivent avoir le format suivant : <i>n° sagex - nom du projet</i> comme par ex. 111111 - projet test
                </li>
                <img src="/img/projet1.png" alt="Projet Outlook" style={{ width: '20%' }} />
                <li>
                    Créer ensuite les événements (durée passée sur le projet) et attribuer la catégorie (projet) <br />
                    Il n'est pas nécessaire de mettre un titre sauf pour les projets socles, dont le titre sera repris comme commentaire dans SageX.

                </li>
                <img src="/img/projet2.png" alt="Assignation de catégorie" />
            </ol>
        </div>
    );
};

export default ImportOutlookPanel;
