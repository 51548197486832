import logo from './logo.svg';
import './App.css';
import Header from "./Header";
import Accordion from "./Accordion";
import ConversionPanelEML from "./ConversionPanelEML";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import ImportOutlookPanel from "./ImportOutlookPanel";
import ExportCSVPanel from "./ExportCSVPanel";
import ImportSagexPanel from "./ImportSagexPanel";
import {ToastContainer} from "react-toastify";
import React, { useState } from 'react';
import ConversionPanelLLEP from "./ConversionPanelLLEP";
import ConversionPanelDefault from "./ConversionPanelDefault";



function App() {

    const [selectedPanel, setSelectedPanel] = useState('eml');

    const handleSelectChange = (event) => {
        setSelectedPanel(event.target.value);
    };

    const renderSelectedPanel = () => {
        switch (selectedPanel) {
            case 'eml':
                return <ConversionPanelEML />;
            case 'llep':
                return <ConversionPanelLLEP/>;
            default:
                return <ConversionPanelDefault />;
        }
    };

  return (
      <div className="App">
          <Header />
          <Accordion title="1) Importer les catégories dans Outlook">
              <ImportOutlookPanel />
          </Accordion>
          <Accordion title="2) Exporter l'agenda dans un fichier CSV">
              <ExportCSVPanel />
          </Accordion>
          <Accordion title="3) Conversion du fichier">
              <h6>
              <label>Lab de recherche:</label>
              <select onChange={handleSelectChange} value={selectedPanel}>
                  <option value="eml">eml</option>
                  <option value="innolab">inno lab</option>
                  <option value="leadlab">lead lab</option>
                  <option value="llep">llep</option>
                  <option value="ell">ell</option>
                  <option value="aucun">aucun</option>
              </select>
              </h6>
              {renderSelectedPanel()}
          </Accordion>
          <Accordion title="4) Importer le fichier Excel dans SageX">
              <ImportSagexPanel />
          </Accordion>
          <Footer />
      </div>
  );
}

export default App;
