import React from 'react';

function Footer() {
    return (
        <div className="footer">
            <p>⭯ <a href="https://dev.azure.com/ExergyManagementLab/_git/Outlook2SageX">2.3.0</a> / Mai 2024 - ✉ <a href="mailto:francois.brouchoud@hevs.ch">francois.brouchoud@hevs.ch</a></p>
        </div>
    );
}

export default Footer;
