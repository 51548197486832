import React from 'react';

function Header() {
    return (
        <div className="header">
            <h1>Outlook 2 SageX</h1>
            <img src="https://www.hevs.ch/media/image/21/fr-de-heg.png" alt="Logo" />
        </div>
    );
}

export default Header;