import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Button, Form, FormGroup, Label, Input} from 'reactstrap';



const ExportCSVPanel = () => {
    return (
        <div>
            <p>Cette étape est à effectuer chaque mois et permet d'exporter le contenu de votre agenda.</p>
                <ol>
                    <li>Se rendre dans fichier</li>
                    <img class="tuto" src="/img/tuto1.png"/>
                    <li>Ouvrir et exporter</li>
                    <img class="tuto" src="/img/tuto2.png" style={{ width: '10%' }}/>
                    <li>Importer et exporter</li>
                    <img class="tuto" src="/img/tuto3.png" style={{ width: '20%' }}/>
                    <li>Exporter des données vers un fichier</li>
                    <img class="tuto" src="/img/tuto4.png"/>
                    <li>Valeurs séparées par une virgule</li>
                    <img class="tuto" src="/img/tuto5.png"/>
                    <li>Choisir Calendrier</li>
                    <img class="tuto" src="/img/tuto6.png" style={{ width: '15%' }}/>
                    <li>Choisir le chemin d'enregistrement</li>
                    <img class="tuto" src="/img/tuto7.png"/>
                    <li>Cocher exporter</li>
                    <img class="tuto" src="/img/tuto8.png"/>
                    <li>Cliquer sur champs personnalisés</li>
                    <li>Effacer le mappage s’il ne correspond pas aux valeurs du point 11</li>
                    <img class="tuto" src="/img/tuto10.png" style={{ width: '15%' }}/>
                    <li>Glisser déposer dans l'ordre: categories, début (2ème), fin (2ème) et objet
                        <br/>
                    <i>Ici l'objet est le nom de l'événement dans l'agenda et est converti en commentaire pour les projets socles.</i>
                    </li>
                    <img class="tuto" src="/img/tuto11.png"/>
                    <li>Ok</li>
                    <li>Terminer</li>
                    <li>Choisir les dates du mois</li>
                    <img class="tuto" src="/img/tuto14.png"/>
                    <li>Exporter</li>
            </ol>
        </div>
    );
};

export default ExportCSVPanel;
