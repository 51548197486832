import React, { useState } from 'react';

function Accordion({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <button className="accordion" onClick={() => setIsOpen(!isOpen)}>
                {title}
            </button>
            {isOpen && <div className="panel">{children}</div>}
        </div>
    );
}

export default Accordion;
